@charset "UTF-8";
/* IMPORTS */
@import url("https://use.typekit.net/wah0hlz.css");
/* GLOBAL */
body {
  background-color: #292a59;
}

p {
  font-family: "sofia-pro", sans-serif;
}

h2 {
  font-family: "sofia-pro", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.3em;
}

button:focus {
  outline: none;
  box-shadow: none;
}

a {
  text-decoration: none;
  color: teal;
}

/* MENU */
.menu {
  position: fixed;
  top: 15px;
  right: 100px;
  height: 75px;
  /*     background-color: rgba(115, 221, 220, 0.4); */
  display: flex;
  align-items: center;
  justify-content: center;
  /*     box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2); */
  z-index: 3;
}
.menu #logo {
  height: 50px;
}
.menu .ig {
  transition: all 150ms ease-in;
}
.menu .ig img {
  height: 50px;
  margin-left: 30px;
}
.menu .ig:hover {
  transform: scale(1.1);
}

.contact-btn {
  padding: 15px 25px 15px 25px;
  font-family: Helvetica, sans-serif;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 14px;
  border-radius: 25px;
  cursor: pointer;
  border: none;
  background-color: #27265a;
  text-decoration: none;
  color: #73ddc6;
  transition: all 150ms ease-in;
  box-shadow: -2px 2px 5px rgba(0, 0, 0, 0.15);
}

.contact-btn:hover {
  transform: scale(1.1);
}

/* HERO */
.hero {
  position: fixed;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(60deg, #88d1c1, #292a59);
  z-index: 0;
}

.hero-block {
  width: 100vw;
  height: 100vh;
  background-color: black;
  position: fixed;
  top: 0;
  opacity: 0;
  z-index: 1;
}

.hero-block-trigger {
  width: 100vw;
  height: 100vh;
  position: relative;
  top: 0;
  z-index: 1;
}

.fade {
  width: 100vw;
  height: 100vh;
  background-color: black;
  position: fixed;
  top: 0;
  opacity: 1;
  z-index: 1;
}

.fade-trigger {
  width: 100vw;
  height: 100vh;
  position: relative;
  top: 0;
  z-index: 2;
}

/* SECTIONS */
.section-grid {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  z-index: 2;
}
.section-grid .fotball {
  height: auto;
  width: 40%;
  padding-left: 10%;
}
.section-grid p {
  line-height: 1.8rem;
}
.section-grid h2  {
  color: blue;
}
.section-grid .section-content {
  width: 900px;
  background-color: white;
  border-radius: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.section-grid .section-content .our-history {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  height: 400px;
}
.section-grid .section-content .our-history img {
  height: 350px;
  border-radius: 15px;
}
.section-grid .section-content .our-history .our-history-content {
  width: 50%;
}
.section-grid .section-content .our-history .our-history-content p {
  width: 80%;
}

/* FOOTER */
.footer {
  width: 100vw;
  height: 50px;
  background-color: rgb(8, 37, 37);
  position: fixed;
  bottom: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer p {
  color: rgba(115, 221, 220, 0.4);
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 6px;
}

/* TRANSITION GROUPS */
.examples--enter {
  opacity: 0;
}

.examples--enter-active {
  opacity: 1;
  transition: all 0.5s;
}

.examples--enter-done {
  opacity: 1;
}

.examples--appear {
  opacity: 0;
}

.examples--appear-active {
  opacity: 1;
  transition: all 0.5s;
}

.examples--appear-done {
  opacity: 1;
}

.examples--exit {
  opacity: 1;
}

.examples--exit-active {
  opacity: 0;
  transition: all 0.5s;
}

.examples--exit-done {
  opacity: 0;
}

@media only screen and (max-width: 1000px) {
  .menu #logo {
    height: 40px;
  }
  .contact-btn {
    right: 20px;
    font-size: 0.5em;
    padding: 10px 10px 10px 10px;
  }
  .section-grid .section-content {
    width: 80vw;
    margin: 100vh 0 300px 0;
  }
  .section-grid .section-content .our-history {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: auto;
    padding: 30px 0 30px 0;
  }
  .section-grid .section-content .our-history img {
    width: 90%;
    height: auto;
  }
  .section-grid .section-content .our-history .our-history-content {
    width: 80%;
    padding-left: 20px;
  }
  .section-grid .section-content2 {
    width: 80vw;
    height: 70vh;
    margin-bottom: 200px;
  }
  .section-grid .section-content2 .examples {
    height: auto;
  }
  .section-grid .section-content2 .examples .example2 {
    margin: 25px;
  }
  .example1-content {
    height: 675px;
    width: 90vw;
  }
  .example1-content .example-video {
    width: 90%;
  }
  .example1-content p {
    width: 90%;
  }
  .example2-content {
    height: 675px;
    width: 90vw;
  }
  .example2-content .example-video {
    width: 90%;
  }
  .example2-content p {
    width: 90%;
  }
  .example3-content {
    height: 675px;
    width: 90vw;
  }
  .example3-content .example-video {
    width: 90%;
  }
  .example3-content p {
    width: 90%;
  }
}
@media only screen and (max-width: 500px) {
  .section-grid {
    margin-bottom: 100px;
  }
  .section-grid .section-content2 {
    height: 90vh;
  }
  .section-grid .section-content2 .examples .example2 {
    margin: 25px 0 25px 0;
  }
}/*# sourceMappingURL=App.css.map */